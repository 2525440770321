import React, { ReactElement, useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { IDeleteObjectRequest, IObjectsRequest, ObjectType } from "@screens/Objects/Objects.types";
import { GENERAL_NAMESPACE, ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { initObject } from "@screens/Objects/Objects.utils";
import { initialOverride, OverrideObjectType } from "@screens/Overrides/Overrides.types";
import { useSelector } from "react-redux";
import RootState from "@state/interfaces";
import { useFilteredData } from "@common/hooks/useFilteredData";
import { errorInfo } from "@state/error";
import { deleteObjectRequest, getObjectsRequest } from "@services/Objects";
import * as commonStyle from "@common/style/style.scss";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { Pending } from "@screens/Panding";
import { EModals } from "@components/Modal/types";
import { checkObjectResponse } from "@common/methods/objectRequest";
import { IErrorResponse } from "@interfaces";
import { accumulateOverrides, overrideHeader } from "@screens/Overrides/Overrides.utils";
import { Modal } from "@components/Modal";
import { NEW_PAGE } from "@common/constant/urls";

const initOverride: OverrideObjectType = {
    ...initObject,
    data: initialOverride,
};

const initialState = initialStateModal(initOverride);
const reducerObjects = reducerModal(initialState);

const dataOverrides: IObjectsRequest = {
    kind: ObjectEnum.CLIENT_CONFIG_OVERRIDE,
    metadata_only: false,
    namespace: GENERAL_NAMESPACE,
};

const filterFunction =
    (searchValue: string) =>
    ({ metadata: { name } }: ObjectType): boolean =>
        name.toLowerCase().includes(searchValue.toLowerCase());

function OverridesScreen(): ReactElement {
    const navigate = useNavigate();
    const [modals, dispatchModal] = useReducer(reducerObjects, initialState);
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const account = useSelector((state: RootState) => state.account);
    const canCreate = useMemo(
        () => objectPermissions[ObjectEnum.CLIENT_CONFIG_OVERRIDE]?.includes(PermissionEnum.PUT),
        [objectPermissions],
    );
    const { setData, setSearch, filteredList } = useFilteredData<ObjectType>(filterFunction);

    const getOverrides = useCallback(async () => {
        const permissions = objectPermissions[ObjectEnum.FEATURE] ?? [];
        const userCanRead = permissions.includes(PermissionEnum.GET);
        if (!userCanRead) {
            errorInfo.setErrorInfo({
                title: window.locales.permissions,
                description: window.locales.noPermissions,
            });
            navigate("/");
            return;
        }

        const { data } = await getObjectsRequest(dataOverrides);

        if (data.objects) {
            setData(data.objects);
        }
    }, [navigate, objectPermissions, setData]);

    const showDeleteModal = (override: OverrideObjectType) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: override,
        });
    };

    const showEditModal = (override: OverrideObjectType) => {
        navigate(override.metadata.name);
    };

    useEffect(() => {
        if (!account.authorized) return;
        getOverrides().then();
    }, [account.authorized, getOverrides]);

    const tableData = useMemo(() => {
        const permissions = objectPermissions[ObjectEnum.FEATURE] ?? [];
        return accumulateOverrides(filteredList, showEditModal, showDeleteModal, permissions);
    }, [filteredList, objectPermissions]);

    const deleteObject = async () => {
        try {
            closeModal();
            const { kind, name, revision: requireRevision } = modals.item.metadata;
            const object: IDeleteObjectRequest = {
                kind,
                name,
                requireRevision,
                returnPrevious: false,
            };
            const { data } = await deleteObjectRequest(object);

            checkObjectResponse(data, () => {});
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            await getOverrides();
        }
    };

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initOverride,
        });
    };

    const showCreateModal = () => navigate(NEW_PAGE);

    if (!account.authorized) {
        return <Pending />;
    }

    return (
        <div className={commonStyle.mainPageContainer}>
            <Table
                tableData={tableData}
                headings={overrideHeader}
                title={window.locales.overrides}
                emptyMessage={window.locales.noOverrides}
            >
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                    <button disabled={!canCreate} className={buttonStyle.buttonAdd} onClick={showCreateModal}>
                        <IconPlus theme="blue" />
                        {window.locales.add}
                    </button>
                </div>
            </Table>

            {/* Modal delete feature */}
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deleteOverride}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteObject,
                }}
            >
                Do you want to delete the override {modals.item.metadata.name}?
            </Modal>
        </div>
    );
}

export default OverridesScreen;
