import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { deleteObjectRequest, editAndCreateObjectRequest, getObjectsRequest } from "@services/Objects";
import { GENERAL_NAMESPACE, ObjectEnum } from "@screens/Objects/Object/Object.constants";
import { useFilteredData } from "@common/hooks/useFilteredData";
import { IDeleteObjectRequest, IObjectRequest, ObjectType } from "@screens/Objects/Objects.types";
import Table from "@components/Table";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { initObject } from "@screens/Objects/Objects.utils";
import { initialStateModal, reducerModal } from "@components/Modal/reducer";
import { EModals } from "@components/Modal/types";
import { RoleModal } from "@screens/Access/Roles/RoleModal";
import Base64toJSON from "@common/methods/base64toJSON";
import { accumulateRoles, roleHeader } from "@screens/Access/Roles/Roles.utils";
import filterEmptyStrings from "@common/methods/filterEmptyStringValues";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { successInfo } from "@state/success";
import { Modal } from "@components/Modal";

export type AccessDataRoleType = {
    allow?: Record<string, string[]>;
    allow_paths?: string[];
};

export type AccessRoleType = {
    name: string;
} & AccessDataRoleType;

const initialState = initialStateModal(initObject);
const reducerObjects = reducerModal(initialState);
const filterFunction =
    (searchValue: string) =>
    ({ metadata: { name } }: ObjectType): boolean =>
        name.toLowerCase().includes(searchValue);

function Roles() {
    const { setData, setSearch, filteredList } = useFilteredData<ObjectType>(filterFunction);
    const [modals, dispatchModal] = useReducer(reducerObjects, initialState);
    const role = useMemo<AccessRoleType>(() => {
        const { data, metadata } = modals.item;
        const { allow = {}, allow_paths = [] } = Base64toJSON(data) as Partial<AccessDataRoleType>;
        return { name: metadata.name, allow, allow_paths };
    }, [modals.item]);

    const showDeleteModal = (object: ObjectType) => {
        dispatchModal({
            type: EModals.DeleteModal,
            payload: object,
        });
    };

    const getData = useCallback(async () => {
        const { data } = await getObjectsRequest({
            kind: ObjectEnum.ROLE,
            metadata_only: false,
            namespace: GENERAL_NAMESPACE,
        });

        if (data && data.objects) {
            setData(data.objects);
        }
    }, []);

    const openEditModal = (object: ObjectType) => {
        dispatchModal({
            type: EModals.EditModal,
            payload: object,
        });
    };

    const tableData = useMemo(() => accumulateRoles(filteredList, openEditModal, showDeleteModal), [filteredList]);

    useEffect(() => {
        getData().then();
    }, [getData]);

    const closeModal = () => {
        dispatchModal({
            type: EModals.CloseModals,
            payload: initObject,
        });
    };

    const createRole = async ({ allow_paths, allow, name }: AccessRoleType) => {
        const object = filterEmptyStrings({
            ...modals.item.metadata,
            name,
            data: jsonToBase64({ allow_paths, allow }),
            kind: ObjectEnum.ROLE,
            namespace: GENERAL_NAMESPACE,
            returnPrevious: false,
            returnCurrent: true,
        }) as IObjectRequest;

        await editAndCreateObjectRequest({
            ...object,
            onlyCreate: true,
            onlyUpdate: false,
        });
        await getData();
        successInfo.setSuccessInfo();
    };

    const editRole = async ({ allow_paths, allow }: AccessRoleType) => {
        const object = filterEmptyStrings({
            ...modals.item.metadata,
            requireRevision: modals.item.metadata.revision,
            data: jsonToBase64({ allow_paths, allow }),
            kind: ObjectEnum.ROLE,
            namespace: GENERAL_NAMESPACE,
            returnPrevious: false,
            returnCurrent: true,
        }) as IObjectRequest;

        await editAndCreateObjectRequest({
            ...object,
            onlyCreate: false,
            onlyUpdate: true,
        });
        await getData();
        successInfo.setSuccessInfo();
    };

    const deleteRole = async () => {
        closeModal();
        const { kind, name, revision: requireRevision } = modals.item.metadata;
        const object: IDeleteObjectRequest = {
            kind,
            name,
            requireRevision,
            returnPrevious: false,
        };

        await deleteObjectRequest(object);
        await getData();
        successInfo.setSuccessInfo();
    };

    const showCreateModal = () => {
        dispatchModal({
            type: EModals.CreateModal,
            payload: initObject,
        });
    };

    return (
        <>
            <Modal
                isNegative
                isOpen={modals.deleteModal}
                onClose={closeModal}
                title={window.locales.deleteRole}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteRole,
                }}
            >
                Do you want to delete {modals.item.metadata.name}?
            </Modal>
            <RoleModal
                closeModal={closeModal}
                title={window.locales.createRole}
                isOpen={modals.createModal}
                action={createRole}
                item={role}
            />
            <RoleModal
                closeModal={closeModal}
                title={window.locales.editRole}
                isOpen={modals.editModal}
                action={editRole}
                item={role}
            />
            <Table tableData={tableData} headings={roleHeader} title="projects" emptyMessage={window.locales.noRoles}>
                <div className={style.tableHeaderContainer}>
                    <div className={style.searchContainer}>
                        <Search callback={setSearch} />
                    </div>
                    <button onClick={showCreateModal} className={buttonStyle.buttonAdd}>
                        <IconPlus theme="blue" />
                        {window.locales.add}
                    </button>
                </div>
            </Table>
        </>
    );
}

export default Roles;
